import { CommonConfig, EnvironmentConfig } from '../app/helpers/config';

export const commonConfig: CommonConfig = {
    authStorage: 'auth',
    authUrlParam: 'token',
    autosaveInterval: 1000,
    beddingOptions: [
        {
            label: '1 Bed',
            value: '1 Bed',
        },
        {
            label: '2 Beds',
            value: '2 Beds',
        },
    ],
    billingProvinceRequiredIn: [
        'CA',
        'US',
    ],
    bodyNoScrollClass: 'rmc-no-scroll',
    bookingsStorageKey: `bookings`,
    clientIdStorage: 'clientId',
    conditionsUrl: 'https://www.rockymountaineer.com/terms-conditions',
    dateFormat: 'MMMM d, y',
    homeCountry: 'CA',
    homeInsuranceProvinces: [
        'CA-BC',
        'CA-ON',
    ],
    insuranceConditions: 'https://www.rockymountaineer.com/sites/default/files/insurance_terms_conditions/allianz_plan_0_23.pdf',
    keyCodes: {
        arrowDown: 'ArrowDown',
        arrowUp: 'ArrowUp',
        enter: 'Enter',
        escape: 'Escape',
        tab: 'Tab',
    },
    locale: 'en_CA',
    mockPackages: false,
    monthFormat: 'MMM y',
    noSeatsError: 409,
    pusherConfig: {
        cluster: 'eu',
        forceTLS: true,
    },
    pusherKey: '28e01bd73c65ccba5ad7',
    recaptchaKey_v2: '6LfZTrMUAAAAALlPvEEVPzTqWMHRUACeZPe1QA6w',
    recaptchaKey_v3: '6Lf7TLMUAAAAACvA3CRGRacB3YY_VeZm1MCAID7l',
    savedTripsPath: 'guest/s/',
    servicePhone: '1-877-460-3200',
    sldsConfig: {
        svgPath: 'assets/icons/', // the only working path for ng-lightning in production mode
    },
    staticData: [
        'cruise_cabin_preference',
        'cruise_meal_seating_preference',
        'dietary_meal_requests',
    ],
};

export const developConfig: EnvironmentConfig = {
    activities: true,
    apiUrl: 'https://ecommerce-staging.kaptioapis.com/',
    channelId: 'c2bf97faa40340d39f78bb3a885c12b4',
    businessUnitToChannelMap: {
        'rocky': 'c2bf97faa40340d39f78bb3a885c12b4',
        'rtr': '513ac9cf140c4e63831c8dbea86de099'
    },
    communitiesUrl: 'https://stgfull-rmountaineer.cs244.force.com/',
    fallbackRedirectLocation: 'https://rockymountaineer.com',
    gtmCode: 'GTM-MZ7GPZX',
    parentDomainRegex: /\/\/[^\/]*?(rockymountaineer\.com|rmd8stg\.prod\.acquia-sites\.com)[^\/]*/i,
    production: false,
    snapengageId: '068fc3bd-b99d-40b0-81b3-b019b0ad2e89'
};

export const sitConfig: EnvironmentConfig = {
    activities: true,
    apiUrl: 'https://ecommerce-staging.kaptioapis.com/',
    channelId: '07596f26070848858b0cd8c571bf7e5e',
    businessUnitToChannelMap: {
        'rocky': '07596f26070848858b0cd8c571bf7e5e',
        'rtr': '57183ce5e85642ba82bec05f111f75c8'
    },
    communitiesUrl: 'https://sit-rmountaineer.cs124.force.com/',
    fallbackRedirectLocation: 'https://rockymountaineer.com',
    gtmCode: 'GTM-MZ7GPZX',
    parentDomainRegex: /\/\/[^\/]*?(rockymountaineer\.com|rmd8stg\.prod\.acquia-sites\.com)[^\/]*/i,
    production: false,
    snapengageId: '068fc3bd-b99d-40b0-81b3-b019b0ad2e89'
};

// SIT Full [s] -  https://ecommerce-checkout-rm-sitfull.kaptioapis.com
export const sitFullConfig: EnvironmentConfig = {
  activities: true,
  apiUrl: 'https://ecommerce-staging.kaptioapis.com/',
  channelId: 'ae3e3816145347df92599f57aec68a05',
  businessUnitToChannelMap: {
    'rocky': 'ae3e3816145347df92599f57aec68a05',
    'rtr': '57183ce5e85642ba82bec05f111f75c8'
  },
  communitiesUrl: 'https://sitfull-rmountaineer.cs123.force.com/',
  fallbackRedirectLocation: 'https://rockymountaineer.com',
  gtmCode: 'GTM-MZ7GPZX',
  parentDomainRegex: /\/\/[^\/]*?(rockymountaineer\.com|rmd8stg\.prod\.acquia-sites\.com)[^\/]*/i,
  production: false,
  snapengageId: '068fc3bd-b99d-40b0-81b3-b019b0ad2e89'
};

// SIT Partial [s] -  https://ecommerce-checkout-rm-sitpartial.kaptioapis.com
export const sitPartialConfig: EnvironmentConfig = {
  activities: true,
  apiUrl: 'https://ecommerce-staging.kaptioapis.com/',
  channelId: 'a00462886eec4c4abd5bcab6449cfe24',
  businessUnitToChannelMap: {
    'rocky': 'a00462886eec4c4abd5bcab6449cfe24',
    'rtr': '69b583cfb33343ad9d9b83615ac49909'
  },
  communitiesUrl: 'https://sitpartial-rmountaineer.cs124.force.com/',
  fallbackRedirectLocation: 'https://rockymountaineer.com',
  gtmCode: 'GTM-MZ7GPZX',
  parentDomainRegex: /\/\/[^\/]*?(rockymountaineer\.com|rmd8stg\.prod\.acquia-sites\.com)[^\/]*/i,
  production: false,
  snapengageId: '068fc3bd-b99d-40b0-81b3-b019b0ad2e89'
};

// KaptioDev -  https://ecommerce-checkout-rm-kaptiodev.kaptioapis.com
export const kaptiodevConfig: EnvironmentConfig = {
    activities: true,
    apiUrl: 'https://ecommerce-staging.kaptioapis.com/',
    channelId: 'e9a70696f76c4bfd8ab820d75883be48',
    businessUnitToChannelMap: {
        'rocky': 'e9a70696f76c4bfd8ab820d75883be48',
        'rtr': '9cc634d7dcce4a52862e5a9fdec7e175'
    },
    communitiesUrl: 'https://kaptiodev-rmountaineer.cs201.force.com/',
    fallbackRedirectLocation: 'https://rockymountaineer.com',
    gtmCode: 'GTM-MZ7GPZX',
    parentDomainRegex: /\/\/[^\/]*?(rockymountaineer\.com|rmd8stg\.prod\.acquia-sites\.com)[^\/]*/i,
    production: false,
    snapengageId: '068fc3bd-b99d-40b0-81b3-b019b0ad2e89'
};

// KHotfix -  https://ecommerce-checkout-rm-khotfix.kaptioapis.com
export const khotfixConfig: EnvironmentConfig = {
    activities: true,
    apiUrl: 'https://ecommerce-staging.kaptioapis.com/',
    channelId: '76bf02de-f374-4fb0-93fc-97f6ccf2319e',
    businessUnitToChannelMap: {
        'rocky': '76bf02de-f374-4fb0-93fc-97f6ccf2319e',
        'rtr': '202e22a6-3441-4268-bff9-6dc17e80094c'
    },
    communitiesUrl: 'https://khotfix-rmountaineer.cs37.force.com/',
    fallbackRedirectLocation: 'https://rockymountaineer.com',
    gtmCode: 'GTM-MZ7GPZX',
    parentDomainRegex: /\/\/[^\/]*?(rockymountaineer\.com|rmd8stg\.prod\.acquia-sites\.com)[^\/]*/i,
    production: false,
    snapengageId: '068fc3bd-b99d-40b0-81b3-b019b0ad2e89'
};

// OP -  https://ecommerce-checkout-rm-op.kaptioapis.com
export const opConfig: EnvironmentConfig = {
    activities: true,
    apiUrl: 'https://ecommerce-staging.kaptioapis.com/',
    channelId: '40d6f609-f344-4354-90e3-5a6db07b62fa',
    businessUnitToChannelMap: {
        'rocky': '40d6f609-f344-4354-90e3-5a6db07b62fa',
        'rtr': '644389f7-ee12-47aa-9700-e106313d73a3'
    },
    communitiesUrl: 'https://rockymountaineer--op.sandbox.my.site.com',
    fallbackRedirectLocation: 'https://rockymountaineer.com',
    gtmCode: 'GTM-MZ7GPZX',
    parentDomainRegex: /\/\/[^\/]*?(rockymountaineer\.com|rmd8stg\.prod\.acquia-sites\.com)[^\/]*/i,
    production: false,
    snapengageId: '068fc3bd-b99d-40b0-81b3-b019b0ad2e89'
};

export const produtionConfig: EnvironmentConfig = {
    activities: true,
    apiUrl: 'https://ecommerce.kaptioapis.com/',
    channelId: 'c257756097ea485a9fc38989d5058dce',
    businessUnitToChannelMap: {
        'rocky': 'c257756097ea485a9fc38989d5058dce',
        'rtr': '632361361e0d4db7b6d7264cf5bdff6f'
    },
    communitiesUrl: 'https://communities.rockymountaineer.com/',
    fallbackRedirectLocation: 'https://rockymountaineer.com',
    gtmCode: 'GTM-5V36N6',
    parentDomainRegex: /\/\/[^\/]*?(rockymountaineer\.com)[^\/]*/i,
    production: true,
    snapengageId: '7f1fa964-0bcb-43d7-8945-d970ddeca4cf'
};

export const stagingConfig: EnvironmentConfig = {
    activities: developConfig.activities,
    apiUrl: developConfig.apiUrl,
    channelId: developConfig.channelId,
    businessUnitToChannelMap: developConfig.businessUnitToChannelMap,
    fallbackRedirectLocation: developConfig.fallbackRedirectLocation,
    gtmCode: produtionConfig.gtmCode,
    parentDomainRegex: developConfig.parentDomainRegex,
    production: true,
    communitiesUrl: developConfig.communitiesUrl,
    snapengageId: developConfig.snapengageId
};

export const localdevConfig: EnvironmentConfig = {
    activities: developConfig.activities,
    //apiUrl: 'http://localhost:8000/',
    apiUrl: 'https://ecommerce-staging.kaptioapis.com/',
    channelId: 'a00462886eec4c4abd5bcab6449cfe24',
    businessUnitToChannelMap: {
        'rocky': 'a00462886eec4c4abd5bcab6449cfe24',
        'rtr': '9cc634d7dcce4a52862e5a9fdec7e175'
    },
    fallbackRedirectLocation: 'http://localhost:7777/',
    gtmCode: developConfig.gtmCode,
    parentDomainRegex: developConfig.parentDomainRegex,
    production: false,
    communitiesUrl: developConfig.communitiesUrl,
    snapengageId: developConfig.snapengageId
};

