<div class="breadcrumbs">
    <div class="container rmc-container">
        <div *ngIf="navigationService.backUrl" class="actions">
            <a class="action" [href]="navigationService.backUrl">
                <img class="action__icon" src="/images/arrow-left.svg" width="25" height="14">
                <span class="action__label">Edit Journey</span>
            </a>
        </div>

        <div class="list">
            <div
                *ngFor="let breadcrumb of breadcrumbs; trackBy: trackBreadcrumb"
                class="breadcrumb"
                [ngClass]="{
                    'breadcrumb--selected': isReady && breadcrumb.selected,
                    'breadcrumb--on': isReady && isActive(breadcrumb)
                }"
            >
                <a href="javascript:void(0)" class="breadcrumb__link" (click)="open(breadcrumb)">
                    <img class="breadcrumb__icon" [src]="isReady && breadcrumb.selected ? '/images/breadcrumb-selected.svg' : '/images/breadcrumb-unselected.svg'" height="17" width="17">
                    <div class="breadcrumb__label">{{breadcrumb.label}}</div>
                </a>
            </div>
        </div>

        <div *ngIf="isReady && !isConfirmation && saveUrl" class="actions">
            <a class="action action--gold" [href]="saveUrl">
                <img class="action__icon" src="/images/icon-save.svg" width="17" height="17">
                <span class="action__label">Save Trip</span>
            </a>

            <button class="action action--gold" type="button" (click)="openEmailModal()">
                <img class="action__icon" src="/images/icon-email.svg" width="18" height="15">
                <span class="action__label">Email Trip</span>
            </button>
        </div>
    </div>
</div>
